<template>
  <v-dialog v-model="model" :width="options.width" @click:outside='closeDialog' persistent>
      <div class="dialog_wrapper">
        <img @click="closeDialog" class="close_dialog" src="@/assets/images/ui/exit.svg" alt="">
        <p class="dialog_title">{{options.title}}</p>
        <slot></slot>
        <v-btn
            v-if="!hideButton"
            color="primary"
            height="44"
            width="160"
            class="mt-4"
            @click="onSubmit"
        >
            {{options.button}}
        </v-btn>
      </div>
    </v-dialog>
</template>

<script>
export default {
    // options
    // dialog width, title, button title
    // model
    // model show/hide dialog
    props: {
        model: Boolean,
        options: Object,
        hideButton: Boolean
    },
    methods: {
        onSubmit() {
            this.$emit('onSubmit')
        },
        closeDialog() {
            this.$emit('closeDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog_wrapper {
    background: #fff;
    position: relative;
    padding: 40px 70px;
    overflow-y: scroll;
    .dialog_title {
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        color: #52B1BA;
    }
}
.close_dialog {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}
</style>