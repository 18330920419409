<template>
    <v-row>      
        <v-col v-for="item, idx in items" :key="idx" :cols="item.cols ? item.cols : 12">
            <v-textarea
                v-if="item.type === 'textarea'"
                name="input-7-4"
                v-model="model[item.model]"
                :label="item.label"
                hide-details
                outlined
                dense
                :readonly='item.readonly'
                :placeholder="item.placeholder ? item.placeholder : ''"
                :rules="[$rules[item.rules]]"
            ></v-textarea>
            <v-text-field
                v-if="item.type === 'text'"
                :type='item.inputType ? item.inputType : "text"'
                :min='0'
                v-model="model[item.model]"
                :label="item.label"
                height="44"
                :readonly='item.readonly'
                hide-details
                outlined
                dense
                :rules="[$rules[item.rules] || false]"
                :placeholder="item.placeholder ? item.placeholder : ''"
                v-mask="item.mask ? item.mask : ''"
                :suffix="item.suffix"
            >
            </v-text-field>
            <v-autocomplete
                v-if="item.type === 'select'"
                :loading="item.loading"
                v-model="model[item.model]"
                :items='item.items'
                :label="item.label"
                :readonly='item.readonly'
                item-text="name"
                item-value="id"
                height="44"
                hide-details
                outlined
                :multiple='item.multiple'
                dense
                :rules="[$rules[item.rules]]"
                :return-object='item.object'
            >
            </v-autocomplete>
            <div v-if="item.type === 'editor'">
                <p class="mb-1">{{item.label}}</p>
                <editor
                    v-model="model[item.model]"
                    apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
                    :init="{
                        height: item.height ? item.height : 500,
                        menubar: false,
                        plugins: item.empty ? [] : [
                        'advlist autolink lists link image charmap',
                        'searchreplace visualblocks code fullscreen',
                        'print preview anchor insertdatetime media',
                        'paste code help wordcount table',
                        ],
                        toolbar: item.empty ? '' :
                        'undo redo | formatselect | bold italic | \
                        alignleft aligncenter alignright | \
                        bullist numlist outdent indent | help',
                    }"
                >
                </editor>
            </div>
            
            <yandex-map
                v-if="item.type === 'map'"
                :controls="['geolocationControl', 'rulerControl', 'typeSelector', 'zoomControl']"
                style="width: 100%; height: 300px"
                :coords="[model[item.latitude], model[item.longitude]]"
                :zoom="4"
                @click="onMapClick"
            >
                <ymap-marker
                    :coords="[model[item.latitude], model[item.longitude]]"
                    marker-id="123"
                    hint-content=""
                />
            </yandex-map>
            <v-file-input
                v-if="item.type === 'image'"
                prepend-icon=""
                ref='fileInput'
                prepend-inner-icon="mdi-camera"
                @change="onFileChange($event, item.model)"
                accept="image/*"
                :label="item.label"
                :multiple='item.multiple'
                height="44"
                :readonly='item.readonly'
                hide-details
                outlined
                dense
                :rules="[$rules[item.rules]]"
            ></v-file-input>
            <v-checkbox
                v-if="item.type ==='checkbox'"
                v-model="model[item.model]"
                :label="item.label"
                :readonly='item.readonly'
                hide-details
            ></v-checkbox>
            <v-menu
                v-if="item.type==='date'"
                :ref="`menu${item.model}`"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="model[item.model]"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="model[item.model]"
                        :label="item.label"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        height="44"
                        hide-details
                        outlined
                        dense
                        :rules="[$rules[item.rules]]"
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                    <v-date-picker
                        :first-day-of-week="1"
                        :multiple='item.multiple'
                        v-model="model[item.model]"
                        no-title
                        scrollable
                        :readonly='item.readonly'
                        :allowed-dates="$allowedDates"
                    >
                <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="saveDate(item), log(model, item)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {
        editor: Editor,
    },
    props: {
        dialog: Boolean,
        model: Object,
        items: Array,
    },
    data: () => ({
        menu: false
    }),
    watch: {
        dialog(val) {
            if (val) {
                this.getSelectItems()
            }
        }
    },
    created() {
        this.getSelectItems()
    },
    methods: {
        log(model, item){
            if(item.type == 'tourdates') {

            }
            console.log(model, item);
        },
        saveDate(item) {
            let refName = `menu${item.model}`
            if (this.$refs[refName]) {
                this.$refs[refName].forEach(menu => {
                    console.log(this.model, item.model);
                    menu.save(this.model[item.model])
                })
            }
        },
        getSelectItems() {
            this.items.forEach(el => {
                if (el.type == 'editor') {
                    el.type = ''
                    setTimeout(() => {
                        el.type = 'editor'
                    }, 0);
                }
            })
            const promises = []
            this.items.forEach(el => {
                if (el.type === 'select') {
                    if (el.hardcoded) {
                        if(el.label == 'Роль') {
                            el.items = [
                                {
                                    name: 'Админ',
                                    id: 'admin'
                                },
                                {
                                    name: 'Менеджер',
                                    id: 'manager'
                                },
                            ]
                        }
                    } else {
                        el.loading = true
                        promises.push(this.$store.dispatch(`lists/${el.getItems}`))
                    }
                }
            })
            Promise.all(promises).then(() => {
                this.items.forEach(el => {
                    if (!el.hardcoded) {
                        el.items = this.$store.getters[`lists/${el.getItems}`]
                        el.loading = false
                    }
                })
            })
        },
        onFileChange(e, modelName) {
            this.model[modelName] = e
        },
        onMapClick(e) {
            this.$emit('onMapClick', e)
        }
    }
}
</script>

<style>

</style>