<template>
    <v-btn @click="onClick" color="primary" height="48">
        <img src="@/assets/images/ui/plus.svg" class="mr-2" alt="">
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    methods: {
        onClick() {
            this.$emit('onClick')
        }
    }
}
</script>

<style lang="scss" scoped>

</style>