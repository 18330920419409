<template>
  <div>
    <div class="page_header">
      <v-text-field
          placeholder="Поиск турагента"
          height="48"
          hide-details
          outlined
          dense
          v-model="search"
          @change="getList"
          class="mx-4"
      >
      </v-text-field>
      <vue-add-button @onClick='onAdd'>
        Добавить
      </vue-add-button>
    </div>
    <v-tabs v-model="tab" class="mt-4 mb-3">
      <v-tab @click="goTab">Все</v-tab>
      <v-tab @click="goTab(true)">Активные</v-tab>
      <v-tab @click="goTab(false)">Не активные</v-tab>
    </v-tabs>
    <v-data-table
        :items='items.results'
        :headers="headers"
        :loading="loading"
        :options.sync="options"
        @click:row='goPage'
        disable-sort
        :server-items-length="items.count"
    >
      <template v-slot:[`item.phone_number`]="{ item }">
        {{ item.phone_number | VMask('+# (###) ### ##-##') }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="item.is_active" icon @click.stop="onEdit(item)">
          <img src="@/assets/images/ui/edit.svg" alt="">
        </v-btn>
        <v-btn v-if="item.is_active" icon @click.stop="onDelete(item)">
          <img src="@/assets/images/ui/delete.svg" alt="">
        </v-btn>
        <v-btn v-if="!item.is_active" icon @click.stop="onActivate(item)">
          <img src="@/assets/images/ui/plus.svg" alt="">
        </v-btn>
      </template>
    </v-data-table>
    <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit'>
      <v-form
          ref="form"
          lazy-validation
      >
        <input-content
            :items="inputItems"
            :model="model"
            :dialog='dialog'
        />
        <v-divider v-if="!model.id" class="my-3"/>
        <h2 v-if="!model.id" class="sub_title">Данные пользователя</h2>
        <input-content
            v-if="!model.id"
            :items="subInputs"
            :model="user"
        />
      </v-form>
    </vue-dialog>
  </div>
</template>

<script>
import vueAddButton from '@/components/ui/vueAddButton.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import {companiesService} from '@/services/companies.service.js'
import {tourAgentService} from '@/services/tourAgent.service.js'
import {setModel} from '@/utils/formatMask.js'
import pagination from "@/utils/pagination"

export default {
  mixins: [pagination],
  components: {
    vueAddButton,
    InputContent,
    VueDialog
  },
  data: () => ({
    user: {},
    dialog: false,
    tab: 0,
    search: null, 
    loading: false,
    items: [],
    model: {},
    subInputs: [
      {
        type: 'text',
        label: 'Имя',
        model: 'first_name',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Фамилия',
        model: 'last_name',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Отчество',
        model: 'middle_name',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Email',
        model: 'email',
        rules: 'email',
        cols: 6
      },
      {
        type: 'text',
        label: 'Телефон',
        mask: '+7 (###) ### ##-##',
        model: 'phone_number',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        inputType: 'password',
        label: 'пароль',
        model: 'password',
        rules: 'required',
        cols: 6
      },
    ],
    inputItems: [
      {
        type: 'text',
        inputType: 'number',
        label: 'ИИН/БИН',
        model: 'bank_details_bin',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Название',
        model: 'name',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Юр. название',
        model: 'manager_fullname',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Юр. адрес',
        model: 'legal_address',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Факт. адрес',
        model: 'actual_address',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Контактное лицо',
        model: 'contact_person',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Телефон',
        mask: '+7 (###) ### ##-##',
        model: 'phone_number',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Email',
        model: 'email',
        rules: 'email',
        cols: 6
      },
      {
        type: 'text',
        label: 'Банковские реквизиты',
        model: 'bank_details_account',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'БИК',
        model: 'bank_details_bik',
        rules: 'required',
        cols: 6
      },
      {
        type: 'text',
        label: 'Банк',
        model: 'bank_details_bank',
        rules: 'required',
        cols: 6
      },
      {
        type: 'date',
        label: 'Срок лицензии',
        model: 'license_date',
        rules: 'required',
        cols: 6
      },
      {
        type: 'textarea',
        label: 'Комментарии',
        model: 'comments',
        rules: 'required',
        cols: 12
      },
      {
        type: 'select',
        label: 'Открыть доступ для',
        model: 'tour_operators',
        multiple: true,
        getItems: 'getTourOperators',
        rules: 'required',
        cols: 12,
        loading: false, 
      },
    ],
    is_active: null, 
    headers: [
      {text: 'Название', value: 'name'},
      {text: 'Контактное лицо', value: 'contact_person'},
      {text: 'Телефон', value: 'phone_number'},
      {text: 'Email', value: 'email'},
      // {text: 'Статус', value: 'status'},
      {text: '', value: 'actions'},
    ]
  }),
  computed: {
    dialogOptions(){
      return {
        width: '711',
        title: this.model.id ? 'Изменить турагента' : 'Создать турагента',
        button: 'Сохранить'
      }
    }
  },
  methods: {
    async getList() {
      this.loading = true
      let params = {
        page: this.options.page,
        size: this.options.itemsPerPage,
        is_active: this.is_active,
        search: this.search
      }
      this.items = await companiesService.getCompanies({company_type: 'agent', ...params})
      this.loading = false
    },
    closeDialog() {
      this.model = {}
      this.user = {}
      this.$refs.form.reset()
      this.dialog = false
    },
    onEdit(item) {
      try {
        this.$loading(true)
        this.model = this.$copy(item)
        delete this.model.logo
        this.dialog = true
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false)
      }
    },
    async onDelete(item) {
      await companiesService.deleteCompany(item.id)
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Успешно диактивировали!",
        color: "success",
      });
      this.getList()
    },
    async onActivate(item) {
      let formdata = new FormData()
      formdata.append('is_active', true)
      await companiesService.patchCompany(formdata, item.id)

      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Успешно активировали!",
        color: "success",
      });
      this.getList()
    },
    goPage(item) {
      if(item.is_active) this.$router.push(`/tourAgent/${item.id}/`)
      else {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Турагент деактивирован!",
          color: "error",
        });
      }
    },
    async onSubmit() {
      if (!this.$refs.form.validate()) return
      try {
        this.$loading(true)
        console.log(this.model);
        if (this.model.id) {
          let model = this.$copy(this.model)
          setModel(model, 'phone_number')
          await companiesService.patchCompany(model, this.model.id)
          this.$store.commit("ui/SET_NOTIFICATION", {
            show: true,
            message: "Успешно изменено!",
            color: "success",
          });
        } else {
          const postData = {user: this.user, ...this.model, company_type: 'agent'}
          postData.license_date = this.$moment(this.model.license_date).valueOf() / 1000
          setModel(postData.user, 'phone_number')
          setModel(postData, 'phone_number')
          await companiesService.postCompany(postData)
          this.$store.commit("ui/SET_NOTIFICATION", {
            show: true,
            message: "Успешно создано!",
            color: "success",
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false)
        this.closeDialog()
        this.getList()
      }
    },
    onAdd() {
      this.dialog = true
      this.model = {}
    },
    goTab(value) {
      if(typeof value == 'boolean') this.is_active = value
      else this.is_active = null
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.page_header {
  display: grid;
  grid-template-columns: 1fr 160px;
}

.sub_title {
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 12px;
  color: #52B1BA;
}
</style>