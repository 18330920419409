import axios from "axios";

export const tourAgentService = {
    async postTourAgents(data) {
        return (await axios.post('/tour-agent-accesses/' , data)).data
    },
    async getTourAgentAccess(params) {
        return (await axios.get('/tour-agent-access/' , params)).data
    },
    // мои турагенты
    async getMyAgents(params){
        return (await axios.get('/my-agents/' , { params })).data
    },
    async getMyAgent(id){
        return (await axios.get(`/my-agents/${id}/`))
    },
    async setMyAgent(id, body){
        return (await axios.put(`/my-agents/${id}/`, body)).data
    },
    async deleteMyAgent(id){
        return (await axios.delete(`/my-agents/${id}/`))
    },
    async getMyAgentEmployees(params){
        return (await axios.get(`/my-agents/employees/`, { params })).data
    },
    
}

