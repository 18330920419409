var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',_vm._l((_vm.items),function(item,idx){return _c('v-col',{key:idx,attrs:{"cols":item.cols ? item.cols : 12}},[(item.type === 'textarea')?_c('v-textarea',{attrs:{"name":"input-7-4","label":item.label,"hide-details":"","outlined":"","dense":"","readonly":item.readonly,"placeholder":item.placeholder ? item.placeholder : '',"rules":[_vm.$rules[item.rules]]},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}}):_vm._e(),(item.type === 'text')?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(item.mask ? item.mask : ''),expression:"item.mask ? item.mask : ''"}],attrs:{"type":item.inputType ? item.inputType : "text","min":0,"label":item.label,"height":"44","readonly":item.readonly,"hide-details":"","outlined":"","dense":"","rules":[_vm.$rules[item.rules] || false],"placeholder":item.placeholder ? item.placeholder : '',"suffix":item.suffix},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}}):_vm._e(),(item.type === 'select')?_c('v-autocomplete',{attrs:{"loading":item.loading,"items":item.items,"label":item.label,"readonly":item.readonly,"item-text":"name","item-value":"id","height":"44","hide-details":"","outlined":"","multiple":item.multiple,"dense":"","rules":[_vm.$rules[item.rules]],"return-object":item.object},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}}):_vm._e(),(item.type === 'editor')?_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(item.label))]),_c('editor',{attrs:{"apiKey":"469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2","init":{
                    height: item.height ? item.height : 500,
                    menubar: false,
                    plugins: item.empty ? [] : [
                    'advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media',
                    'paste code help wordcount table' ],
                    toolbar: item.empty ? '' :
                    'undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | help',
                }},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}})],1):_vm._e(),(item.type === 'map')?_c('yandex-map',{staticStyle:{"width":"100%","height":"300px"},attrs:{"controls":['geolocationControl', 'rulerControl', 'typeSelector', 'zoomControl'],"coords":[_vm.model[item.latitude], _vm.model[item.longitude]],"zoom":4},on:{"click":_vm.onMapClick}},[_c('ymap-marker',{attrs:{"coords":[_vm.model[item.latitude], _vm.model[item.longitude]],"marker-id":"123","hint-content":""}})],1):_vm._e(),(item.type === 'image')?_c('v-file-input',{ref:"fileInput",refInFor:true,attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-camera","accept":"image/*","label":item.label,"multiple":item.multiple,"height":"44","readonly":item.readonly,"hide-details":"","outlined":"","dense":"","rules":[_vm.$rules[item.rules]]},on:{"change":function($event){return _vm.onFileChange($event, item.model)}}}):_vm._e(),(item.type ==='checkbox')?_c('v-checkbox',{attrs:{"label":item.label,"readonly":item.readonly,"hide-details":""},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}}):_vm._e(),(item.type==='date')?_c('v-menu',{ref:("menu" + (item.model)),refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.model[item.model],"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, item.model, $event)},"update:return-value":function($event){return _vm.$set(_vm.model, item.model, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":item.label,"prepend-inner-icon":"mdi-calendar","readonly":"","height":"44","hide-details":"","outlined":"","dense":"","rules":[_vm.$rules[item.rules]]},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"multiple":item.multiple,"no-title":"","scrollable":"","readonly":item.readonly,"allowed-dates":_vm.$allowedDates},model:{value:(_vm.model[item.model]),callback:function ($$v) {_vm.$set(_vm.model, item.model, $$v)},expression:"model[item.model]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.saveDate(item), _vm.log(_vm.model, item)}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }