export default{
    data () {
        return {
            options: {},
        }
    },
    watch: {
        options: {
          handler () {
            this.getList()
          },
          deep: true,
        },
      },
}